




















































































import { Component, Vue } from 'vue-property-decorator'
import LayoutStoreFirst from '@/layouts/LayoutStoreFirst.vue'
import SelectStoreService from '@/services/SelectStoreService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: '明治屋ネットストアー,明治屋ネットスーパー'
      },
      {
        name: 'description',
        content:
          '明治屋ネットストアーのトップページ。ご自宅にいながら高鮮度・高品質の商品をご注文いただける「明治屋ネットスーパー」は、「旬」の美味しさをご提供する生鮮品〔精肉・鮮魚・青果〕をはじめ、マイジャム類・おいしい缶詰をはじめとした信頼の明治屋オリジナルMYブランド、明治屋直輸入食品、酒類、こだわりのデリベイク明治屋ハム・ソーセージ製品、明治屋ストアー推奨品などをバラエティ豊かにご用意。当日12時までのご注文で、当日中にご自宅にお届けします。'
      }
    ]
  },
  components: {
    LayoutStoreFirst
  }
})
export default class StoreFirst extends Vue {
  public message: boolean = false
  public messageError: boolean = false

  private zipcodeFirst: string = ''
  private zipcodeLast: string = ''
  @Auth.Getter
  private isLoggedIn!: boolean
  @ModeBuyProduct.Action
  private SET_NEW_MODE_BUY_PRODUCT!: (data: any) => any

  created() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'home' })
    } else {
      localStorage.removeItem('tour_mode')
      localStorage.removeItem('register_mode')
      localStorage.removeItem('store_uuid')
      localStorage.removeItem('store_id')
      localStorage.removeItem('store_name')
      this.SET_NEW_MODE_BUY_PRODUCT('1')
    }
  }

  public async handleSearch(): Promise<void> {
    this.messageError = false
    let zip_code = this.zipcodeFirst.concat(this.zipcodeLast)
    if (zip_code.length !== 7) {
      this.messageError = true
    } else {
      await SelectStoreService.selectStore(zip_code).then(
        (response: any) => {
          if (response.data.items) {
            if (response.data.items.length === 0) {
              this.message = true
            } else {
              localStorage.setItem('items', JSON.stringify(response.data.items))
              this.$router.push({
                name: 'select-store-success'
              })
            }
          }
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        }
      )
    }
  }

  public goToSelectStore() {
    localStorage.removeItem('items')
    localStorage.setItem('tour_mode', 'on')
    this.$router.push({
      name: 'select-store-success'
    })
  }
}
