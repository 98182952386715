import Const from '../constants'
import axiosApiInstance from '../interceptors'

class SelectStoreServeice {
  selectStore(zip_code: string) {
    return axiosApiInstance.get(Const.api_url + 'store?zip_code=' + zip_code)
  }
}

export default new SelectStoreServeice()
